<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddTypeDialog"
            >+ 新增分组</el-button
          >
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="airGroupList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="id" width="100" aligin="center" prop="id">
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column label="分组名称" aligin="center" prop="groupName">
        </el-table-column>
        <!-- 第三列备注 -->
        <el-table-column label="备注" aligin="center" prop="remark">
        </el-table-column>
        <!-- 第七列操作 -->
        <el-table-column label="操作" width="300" aligin="center">
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditTypeDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeTypeById(scope.row.id)"
              >删除</el-button
            >
            <!-- 问题列表 -->
            <el-button size="mini" type="warning" @click="goAirList(scope.row)"
              >设备列表</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addGroupDialogVisible"
      width="30%"
      @close="clearInfoDialog"
    >
      <el-form
        ref="groupRef"
        :rules="addGroupRules"
        :model="airMonitorGroupForm"
        label-width="80px"
      >
        <el-form-item label="分组名称" prop="groupName">
          <el-input
            v-model="airMonitorGroupForm.groupName"
            clearable=""
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="airMonitorGroupForm.remark"
            clearable=""
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGroupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketId: ''
      },
      // 分组列表
      airGroupList: [],
      total: 0,
      // 新增的入参
      airMonitorGroupForm: {
        groupName: '',
        marketId: undefined,
        remark: ''
      },
      // 标题
      title: '',
      // 提交类型
      submitType: '',
      // 修改id
      id: '',
      // 对话框
      addGroupDialogVisible: false,
      addGroupRules: {
        groupName: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 获取分组列表
    this.getAirGroupList()
  },
  methods: {
    //  定义方法
    async getAirGroupList() {
      // 给marketId赋值
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('air/airMonitorGroup/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('获取分组失败')
      }
      this.airGroupList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getAirGroupList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getAirGroupList()
    },
    // 新增
    showAddTypeDialog() {
      this.title = '新增分组'
      this.submitType = 'add'
      this.addGroupDialogVisible = true
    },
    // 关闭对话框清空信息
    clearInfoDialog() {
      this.airMonitorGroupForm.groupName = ''
      this.airMonitorGroupForm.remark = ''
    },
    // 提交
    submit() {
      this.$refs.groupRef.validate(async valid => {
        if (!valid) return
        if (this.submitType === 'add') {
          this.airMonitorGroupForm.marketId = window.sessionStorage.getItem(
            'currentMarketId'
          )
          const { data: res } = await this.$http.post(
            'air/airMonitorGroup/',
            this.airMonitorGroupForm
          )
          if (res.code !== 0) {
            return this.$message.error('添加分组失败')
          }
          this.$message.success('添加分组成功')
          this.addGroupDialogVisible = false
          this.getAirGroupList()
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `air/airMonitorGroup/${this.id}`,
            this.airMonitorGroupForm
          )
          if (res.code !== 0) {
            return this.$message.error('修改分组失败')
          }
          this.$message.success('修改分组成功')
          this.addGroupDialogVisible = false
          this.getAirGroupList()
        }
      })
    },
    // 修改按钮
    showEditTypeDialog(row) {
      this.airMonitorGroupForm.groupName = row.groupName
      this.airMonitorGroupForm.marketId = row.marketId
      this.airMonitorGroupForm.remark = row.remark
      this.id = row.id
      this.title = '修改分组'
      this.submitType = 'edit'
      this.addGroupDialogVisible = true
    },
    // 删除
    async removeTypeById(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该分组和分组下的设备，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`air/airMonitorGroup/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除分组失败')
      }
      this.$message.success('删除分组成功')
      this.getAirGroupList()
    },
    // 跳转到设备列表
    goAirList(row) {
      this.$router.push({
        path: 'air-list',
        query: { id: row.id, groupName: row.groupName }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
